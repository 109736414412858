
/**
 * Represents a platform
 * @extends Object
 */
class Platform {
    constructor(object=null) {
        /**
         * @type {String}
         */
        this.title = "";

        /**
         * @type {Integer}
         */
        this.id = 0;

        if (object) {
            Object.assign(this, object);
        }
    }
}

export default Platform;
