export default {
    state: {
        models: []
    },

    mutations: {
        "Models.add": (state, models) => {
            models.forEach((model) => {
                state.models.push(model);
            });
        },
        "Models.set": (state, models) => state.models = models,
        "Models.update": (state, models) => {
            models.forEach((model) => {
                state.models[state.models.findIndex(m => m.id === model.id)].online = model.online;
            });
        }
        /*"Models.set.filters": (state, filters) => {state.filters = filters;},
        "Models.remove.filter": (state, filter) => {
            state.filters.splice(1, state.filter.findIndex(x => x.name === filter.name));
        },
        "Models.update.filter": (state, update) => {
            let index = state.filters.findIndex((filter) => filter.name === update.name);
            state.filters.splice(index, 1, update);
        },
        "Models.clear.filters": (state) => {
            state.filters.splice(0, state.filters.length);
        }*/
    },

    getters: {
        models: state => state.models
    }
};
