import Vue from 'vue'
import Router from 'vue-router'

import Full from '../container/Full.vue'
import App from '../App.vue'
import Click from '../components/Click.vue'
import Profile from '../components/Profile.vue'
Vue.use(Router);

export default new Router({
    mode: 'history',
    linkActiveClass: 'open active',
    scrollBehavior: () => ({ y: 0 }),
    routes: [
        {
            path: '/',
            component: Full,
            children: [
                {
                    path: '/',
                    name: 'Home',
                    component: App,
                    props: true
                },
                {
                    path: 'search/:search?',
                    name: 'Search',
                    component: App,
                    props: true
                },
                {
                    path: 'click',
                    name: 'Click',
                    component: Click,
                    props: true
                },
                {
                    path: ':camsite/:profile',
                    name: 'Profile',
                    component: Profile,
                    props: true
                }
            ]
        }
    ]
})