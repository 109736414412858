export default {
    state: {
        filters: []
    },

    mutations: {
        "Filters.add": (state, filter) => {
            state.filters.push(filter);
        }
    },

    getters: {
        filters: state => state.filters,
        active_filters: state => state.filters.filter(filter => filter.is_active),
        inactive_filters: state => state.filters.filter(filter => !filter.is_active),
        filter_by_name: state => name => state.filters.filter(filter => filter.name == name)[0]
    }
};
