import CONF from "../conf";
import axios from "axios";
import Filter from "../types/Filter";

export default {
    data(){
        return{
            genders: [
                {value:"f",title:"Female"},
                {value:"m",title:"Male"},
                {value:"c",title:"Couple"},
                {value:"t",title:"Transsexual"}
            ],
            ages: [
                {title: "18-20", min: 18, max:20},
                {title: "20-25", min: 20, max:25},
                {title: "25-30", min: 25, max:30},
                {title: "30-40", min: 30, max:40},
                {title: "40-50", min: 40, max:50},
                {title: "50+"  , min: 50, max:999}
            ]
        }
    },
    computed: {
        /*availableFilters() { return {
            platforms: this.$store.getters.filter_by_name('platforms'),
            countries: this.$store.getters.filter_by_name('countries')
        }; },*/
        platforms() { return this.$store.getters.platforms; },
        countries() { return this.$store.getters.countries; },
        
        selectedPlatforms: {
            get() {
                return this.$store.getters.filter_by_name('platforms').value;
            },
            set(val) {
                this.$store.getters.filter_by_name('platforms').value = val;
            }
        },
        selectedGenders: {
            get() {
                return this.$store.getters.filter_by_name('genders').value;
            },
            set(val) {
                this.$store.getters.filter_by_name('genders').value = val;
            }
        },
        selectedAge: {
            get() {
                return this.$store.getters.filter_by_name('age').value;
            },
            set(val) {
                this.$store.getters.filter_by_name('age').value = val;                
            }
        },
        selectedCountries: {
            get() {
                return this.$store.getters.filter_by_name('countries').value;
            },
            set(val) {
                this.$store.getters.filter_by_name('countries').value = val;
            }
        },
        currentSearchWord: {
            get() {
                return this.$store.getters.filter_by_name('search').value;
            },
            set(val) {
                this.$store.getters.filter_by_name('search').value = val;
            }
        }
    },
    methods: {
        filterChange(filter, prop, value) {},
        setFilterFromParams() {
            if (this.$route.query.c) {
                this.selectedPlatforms = this.$route.query.c.split(',').filter(i => {return this.platforms.find(p => p.id == i)}).map(i => {return this.platforms.find(p => p.id == i)});
            }
            if (this.$route.query.camsite) {
                this.selectedPlatforms = this.$route.query.camsite.split(',').filter(i => {return this.platforms.find(p => p.id == i)}).map(i => {return this.platforms.find(p => p.id == i)});
            }
            if (this.$route.query.gender) this.selectedGenders = this.$route.query.gender.split(',').filter(i => {return this.genders.find(g => g.value == i)}).map(i => {return this.genders.find(g => g.value == i)});
            if (this.$route.query.g && this.selectedGenders.length < 1) this.selectedGenders = this.$route.query.g.split(',').filter(i => {return this.genders.find(g => g.value == i)}).map(i => {return this.genders.find(g => g.value == i)});
            if (this.$route.query.min_age && this.$route.query.max_age) this.selectedAge = this.ages.filter(a => a.min == this.$route.query.min_age || a.max == this.$route.query.max_age);
            if (this.$route.query.countries) this.selectedCountries = this.$route.query.countries.split(',');
            if (this.$route.query.keyword) this.currentSearchWord = this.$route.query.keyword;
        },
        urlParams(simple = false) {
            let query = {};
            Object.assign(query, this.selectedPlatforms.length > 0          ? {camsite: this.selectedPlatforms.map(o=>o.id).join(',')} : null,
                                !simple && this.selectedPlatforms.length > 0? {c: this.selectedPlatforms.map(o=>o.id).join(',')} : null,
                                this.selectedGenders.length > 0             ? {gender: this.selectedGenders.map(o => o.value).join(',')} : null,
                                !simple && this.selectedGenders.length > 0  ? {g:this.selectedGenders.map(o => o.value).join(',')} : null,
                                this.selectedAge.length > 0                 ? { min_age: Math.min.apply(Math, this.selectedAge.map(function(o){return o.min;})), max_age: Math.max.apply(Math, this.selectedAge.map(function(o){return o.max;}))} : null,
                                this.selectedCountries.length > 0           ? {countries:  this.selectedCountries.map(o=>o.id).join(',')} : null,
                                this.currentSearchWord                      ? {keyword: this.currentSearchWord[0]} : null
            );
            return query;          
        }
        
    }

/*
    computed: {
        availableFilters() {
            return {
                onlineFilter: this.$store.getters.filters[
                    this.$store.getters.filters.findIndex((filter) => filter.name === "onlineFilter")
                ],
                countryFilter: this.$store.getters.filters[
                    this.$store.getters.filters.findIndex((filter) => filter.name === "countryFilter")
                ],
                platformFilter: this.$store.getters.filters[
                    this.$store.getters.filters.findIndex((filter) => filter.name === "platformFilter")
                ]
            };
        },

        countries() {
            return this.$store.getters.countries;
        },

        selectedCountries: {
            get() {
                return this.availableFilters.countryFilter.value;
            },
            set(value) {
                let filter = this.availableFilters.countryFilter;
                filter.value = value;
                this.$store.commit("Models.update.filter", filter);
            }
        },

        platforms() {
            return this.$store.getters.platforms;
        },

        selectedPlatforms: {
            get() {
                return this.availableFilters.platformFilter.value;
            },
            set(value) {
                let filter = this.availableFilters.platformFilter;
                filter.value = value;
                this.$store.commit("Models.update.filter", filter);
            }
        }
    },

    methods: {
        filterChange(filter, prop, value) {
            let active = true;
            if (value === false || value === "" || (Array.isArray(value) && value.length < 1)) {
                active = false;
            }
            filter = this.availableFilters[filter];
            filter.prop = prop;
            filter.value = value;
            filter.active = active;
            this.$store.commit("Models.update.filter", filter);
        }
    },

    created() {
        this.$store.commit("Models.set.filters", [
            new Filter({
                name: "platformFilter",
                prop: "platform",
                active: false,
                value: [],
                method: (model, value) => {
                    return value.findIndex(platform => model.platform === platform) > -1;
                }
            })
        ]);

        axios.get(CONF.api.urls.platforms)
        .then((response) => {
            let platforms = [];
            response.data.forEach((platform) => {
                platforms.push(platform.title);
            });
            this.$store.commit("Models.set.platforms", platforms);
        })
        .catch((error) => {
            console.log("Failed to get platforms");
        });
    }
*/
}
