import Model from "./Model";

class Filter {
    static __transform(value) {
        if (value === null || value === undefined) return '';
        if (value.constructor == Boolean) return value ? 1 : 0;
        if (value.constructor == Array) return value.join(',');
        return value;
    }

    constructor(object) {
        /**
         * Name of the filter in question
         * @type {String}
         */
        this.name = "";

        /**
         * Whichever property to filter by
         * @type {String|Boolean} set to false to filter the root item of an Array
         */
        this.prop = false;

        /**
         * The value to filter by
         * @type {Array|String|Number|Float|Boolean|Null}
         */
        this.value = null;

        /**
         * Wether this filter should be used when filtering things
         * @type {Boolean}
         */
        this.is_active = false;

        /**
         * The method of filtering
         * @param  {Model} item The Model that is being filtered
         * @param  {Array|String|Number|Float|Boolean|Null} value the value the Model is filtered for
         * @return {Boolean}
         */
        //this.method = (item, value) => {return true};
        this.transform = value => this.constructor.__transform(value);

        if (object) {
            Object.assign(this, object);
        }
    }

    query(query) {
        query[this.prop] = this.transform(this.value);
        return query;
    }

    /**
     * Activated this filter
     * @return {undefined}
     */
    activate() {
        this.active = true;
    }

    /**
     * Deactivates this filter
     * @return {undefined}
     */
    deactivate() {
        this.active = false;
    }
}

export default Filter;
