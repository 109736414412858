export default {
    state: {
        platforms: []
    },

    mutations: {
        "Platforms.set": (state, platforms) => {
            state.platforms = platforms;
        }
    },

    getters: {
        platforms: state => state.platforms,

        platformByName: state => (title) => {
            return state['platforms'].find(item => item.title === title);
        },
    }
};
