export default {
    api: {
        urls: {
            countries: conf.env.URL_COUNTRIES,
            platforms: conf.env.URL_PLATFORMS,
            models: conf.env.URL_MODELS,
            moreModels: conf.env.URL_MOREMODELS,
            models_keyword: conf.env.URL_MODELS_KEYWORD,
            moreModels_keyword: conf.env.URL_MOREMODELS_KEYWORD,
            profile: conf.env.URL_PROFILE
        },
        intervals: {
            models: 10000
        }
    }
};
