require('intersection-observer')

import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import Full from './container/Full.vue'
import Vuetify from 'vuetify'
import router from './router/index.js'
import VueAnalytics from 'vue-analytics'
import colors from 'vuetify/es5/util/colors'
import 'vuetify/dist/vuetify.css'
import axios from 'axios';
import { VLazyImagePlugin } from 'v-lazy-image'
import VueYandexMetrika from 'vue-yandex-metrika'

import Platform from './types/Platform';
import Country from './types/Country';
import Filter from './types/Filter';
import ModelStore from "./store/Models";
import PlatformStore from './store/Platforms';
import CountriesStore from './store/Countries';
import FilterStore from './store/Filters';
import CONF from "./conf.js";

Vue.use(Vuex);
Vue.use(VLazyImagePlugin);
/*Vue.use(VueYandexMetrika, {
    id: 51533573,
    router: router,
    env: process.env.NODE_ENV,
    debug: true
});*/
Vue.use(Vuetify, {
    theme: {
        primary: colors.pink.accent2,
        secondary: colors.blue.lighten2,
        accent: colors.shades.black,
        error: colors.red.darken4,
        info: colors.lightBlue.darken1,
        success: colors.green.base,
        warning: colors.orange.darken1
    }
});

const store = new Vuex.Store({
    state: {},

    actions: {},

    mutations: {},

    getters: {},

    modules: {
        ModelStore,
        PlatformStore,
        CountriesStore,
        FilterStore
    }
});

axios.get(CONF.api.urls.platforms)
.then((response) => {
    store.commit('Platforms.set', response.data.map(platform => new Platform(platform)));

    store.commit('Filters.add', new Filter({
        name: 'platforms',
        prop: 'c',
        prop2: 'camsite',
        value: [],
        transform: platforms => platforms.map(p => p.id).join(',')
    }));
    store.commit('Filters.add', new Filter({
        name: 'genders',
        prop: 'g',
        prop2: 'gender',
        value: []
    }));
    store.commit('Filters.add', new Filter({
        name: 'age',
        prop: 'age',
        prop2: 'age',
        value: []
    }));
    store.commit('Filters.add', new Filter({
        name: 'search',
        prop: 'keyword',
        prop2: 'keyword',
        value: ""
    }));
    //get countries for filter
    axios.get(CONF.api.urls.countries)
    .then((response) => {
        store.commit('Countries.set', response.data.data.country.data.map(country => new Country(country)));
    }).catch((error) => {
        console.log("failed to get countries", error);
    });

    store.commit('Filters.add', new Filter({
        name: 'countries',
        prop: 'countries',
        prop2: 'countries',
        value: []
    }));

    

    window.main = new Vue({
        el: '#app',
        router: router,
        store,
        template: '<Full/>',
        components: {Full}
    });
})
.catch((error) => {
    console.log("failed to get platforms", error);
});


