export default {
    state: {
        countries: []
    },

    mutations: {
        "Countries.set": (state, countries) => {
            state.countries = countries;
        }
    },

    getters: {
        countries: state => state.countries
    }
};
