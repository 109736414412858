import axios from "axios";
import CONF from "../conf.js";
import Model from "../types/Model";
import URLBuilder from '../helpers/URLBuilder';
import FilterService from "./FilterService";


export default {
    mixins: [FilterService],
    methods: {
        getModels() {
            this.$root.$emit("filtersUpdated");

            let query = this.urlParams();
            var filters = this.$store.getters.filters;
            let useKeywordEndpoint = filters.find(o => ((o.prop == "age" && o.value && o.value.length > 0) || (o.prop == "tags" && o.value && o.value.length > 0) || (o.prop == "keyword" && o.value && o.value.length > 0))) ? true : false;
            let propField = useKeywordEndpoint ? 'prop2' : 'prop';


            //set to max load profiles
            //160 - profile card preview width in px
            //170 - profile card preview height in px
            //(window.innerWidth - 48) - window width - inner padding
            query.n =  Math.floor((window.innerWidth - 48)/160) * Math.floor(window.innerHeight/170);
            let url = URLBuilder.build(useKeywordEndpoint ? CONF.api.urls.models_keyword : CONF.api.urls.models, query);
            return axios.get(url)
            .then(response => this.$store.commit('Models.set', response.data.map(model => new Model(model))))
            .catch(error => console.log('Failed to get models', error));
        },

        refreshModels() {},
        getMoreModels(page) {
            //to refactor
            let query = this.urlParams();
            var filters = this.$store.getters.filters;
            let useKeywordEndpoint = filters.find(o => ((o.prop == "age" && o.value && o.value.length > 0) || (o.prop == "tags" && o.value && o.value.length > 0) || (o.prop == "keyword" && o.value && o.value.length > 0))) ? true : false;
            let propField = useKeywordEndpoint ? 'prop2' : 'prop';

            //set to max load profiles
            //160 - profile card preview width in px
            //170 - profile card preview height in px
            //(window.innerWidth - 48) - window width - inner padding
            query.n =  Math.floor((window.innerWidth - 48)/160) * Math.floor(window.innerHeight/170);

            //exclude parameter for profiles endpoint
            query["x"] = this.$store.getters.models.map(m => m.id).join(',');

            //let query = this.$store.getters.filters.reduce((q, f) => f.query(q), {x: this.$store.getters.models.map(m => m.id).join(',')});
            let url = URLBuilder.build(useKeywordEndpoint ? CONF.api.urls.models_keyword : CONF.api.urls.models, query);
            return axios.get(url)
            .then(response => {
                this.$store.commit('Models.add', response.data.map(model => new Model(model)));
                return response.data.length > 1;
            })
            .catch(error => console.log('Failed to get models', error));
        }

        /*refreshModels() {
            if (typeof window === "undefined") return;
            let vm = this;

            setTimeout(() => {
                let models = vm.$store.getters.models;

                axios.get(CONF.api.urls.models + "/" + models.map(m => m.id).join(','))
                .then((response) => {
                    vm.$store.commit("Models.update.models", response.data);
                    vm.refreshModels();
                })
                .catch((error) => {
                    console.log("Failed to refresh models", error);
                })
            }, CONF.api.intervals.models);
        },

        getMoreModels() {
            if (typeof window === "undefined") return;

            let vm = this;

            setTimeout(() => {
                vm.loading = true;
                axios.get(CONF.api.urls.moreModels + vm.$store.getters.models.map((model) => {return model.id}).join(','))
                .then((response) => {
                    vm.$store.commit("Models.add.models", response.data.map(model => new Model(model)));
                    vm.loading = false;
                })
                .catch((error) => {
                    console.log("Failed to get more models", error);
                });
            }, 1000);
        }*/
    }
};
