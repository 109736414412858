
/**
 * Represents a model (person)
 * @extends Object
 */
class Model {
    constructor(object=null) {
        /**
         * @type {String}
         */
        this.name = "";

        /**
         * @type {String}
         */
        this.platform = "";

        /**
         * @type {String}
         */
        this.thumb = "";

        /**
         * @type {Integer}
         */
        this.id = 0;

        /**
         * @type {String}
         */
        this.url = "";

        /**
         * @type {Boolean}
         */
        this.stream = false;

        if (object) {
            Object.assign(this, object);
        }
    }
}

export default Model;
